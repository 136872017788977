<template>
  <div class="dynamic-monitoring-alarm">
    <!-- <div class="content"> -->
      <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
          :rules="rules"
        >
          <el-form-item label="所属车队：" prop="companyIds">
            <companySelectForSearch
              ref="companySelectForSearch"
              :multiple="true"
              :searchable="true"
              @getValue="getGroupIds"
            />
          </el-form-item>

          <el-form-item label="车牌号：">
            <car-tree
              @getData="getVehIds"
              ref="carTree"
            ></car-tree>
          </el-form-item>

          <el-form-item label="报警类型：">
            <el-cascader
              v-model="form.alarmType"
              :options="alarmOptions"
              :props="affirmAlarmProps"
              size="small"
              collapse-tags
              clearable
              filterable
            ></el-cascader>
          </el-form-item>

          <el-form-item label="报警等级：" prop="alarmLevel">
            <el-select clearable v-model="form.alarmLevel" placeholder="请选择">
              <el-option
                v-for="item in dictList.alarmLevelList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始时间：" prop="beginTime">
            <el-date-picker
              v-model="form.beginTime"
              type="date"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="报警来源：">
            <el-select
              clearable
              v-model="form.alarmSource"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.alarmSourceList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警判定：">
            <el-select
              clearable
              v-model="form.alarmConfirm"
              placeholder="请选择"
            >
              <el-option
                v-for="item in alarmConfirmList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="alarm-btn">   
                     <!-- v-if="$store.state.menu.nowMenuList.indexOf('导出') >= 0" -->
            <el-button
              size="small"
              type="primary"
              :loading="downLoadStatus"
              @click="exportData"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import {
  getStartDate,
  getCurentDate,
  checkTimeEqualLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'

import {
  getAlarmNameList,
  queryAlarmListV2,
  queryDictsByCodes
} from '@/api/lib/api.js'
import moment from "moment";
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    })
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }

    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.endTime)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.beginTime, value)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    return {
      form: {
        system: sessionStorage.getItem('system').toString(), //对应系统
        beginTime: new Date(), // 开始时间
        endTime: new Date(), // 结束时间
        companyIds: [],
        vehicleNos: [],
        alarmLevel: '', // 报警等级
        alarmType: [], // 报警类型
        alarmSource: '', // 报警来源
        credentialsNo: [], // 驾驶员
        alarmConfirm: '',//报警判定
      },
      dictList: {},
      dictObj: {},
      rules: {       
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
        alarmType: [
          {
            required: true,
            message: '请至少选择一个报警类型',
            trigger: 'blur'
          }
        ]
      },
      props: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      alarmOptions: [],
      alarmAllOptions: [],
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
          // return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      downLoadStatus: false,
      alarmConfirmList: [
        {
          dictValue: '全部',
          dictCode: ''
        },
        {
          dictValue: '待确认',
          dictCode: '0'
        },
        {
          dictValue: '确认报警',
          dictCode: '1'
        },
        {
          dictValue: '误报',
          dictCode: '3'
        },
        {
          dictValue: '报备',
          dictCode: '2'
        },
      ],
    }
  },
  created () {
    this.form.system = sessionStorage.getItem('system').toString();
    this.getDicts();
  },
  methods:{
    
    //获取字典值
    getDicts () {
      queryDictsByCodes({
        parentCodes:
          'BJDJ,CLLX,BJLY,CSCL,BJCLZT,BJZT,BJZL,BJCLFS,D0008,D0009,D0010,BJQR'
      }).then((res) => {
        if (res.code === 1000) {
          res.data.BJZL.splice(2, 1) //删除终端故障报警配置
          this.dictList = {
            alarmConfirmList: res.data.BJQR,
            alarmLevelList: res.data.BJDJ,
            disposeTypeList: res.data.CLLX,
            alarmSourceList: res.data.BJLY,
            timeoutTypeList: res.data.CSCL,
            disposeStatusList: res.data.BJCLZT,
            alarmStatusList: res.data.BJZT,
            alarmList: res.data.BJZL,
            disposeWayList: res.data.BJCLFS
          }
          this.dictObj = {
            disposeWayObj: formatDict(res.data.BJCLFS),
            alarmLevelObj: formatDict(res.data.BJDJ),
            disposeTypeObj: formatDict(res.data.CLLX),
            alarmSourceObj: formatDict(res.data.BJLY),
            timeoutTypeObj: formatDict(res.data.CSCL),
            disposeStatusObj: formatDict(res.data.BJCLZT),
            alarmStatusObj: formatDict(res.data.BJZT),
            D0008Obj: formatDict(res.data.D0008),
            D0009Obj: formatDict(res.data.D0009),
            D0010Obj: formatDict(res.data.D0010),
            typeObj: formatDict(
              [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
            )
          }
          //处理状态 设置默认未处理
          // this.form.disposeStatus = res.data.BJCLZT[0].dictCode

        }
      }).then(() => {
        this.getAlarmTypeFn()
      })
    },
    // 获取报警类型
    getAlarmTypeFn () {
      const self = this
      self.alarmOptions = []
      self.alarmAllOptions = []
      self.dictList.alarmList.forEach((item) => {
        self.alarmOptions.push({
          label: item.dictValue,
          value: item.dictCode,
          children: []
        })
      })
      getAlarmNameList({ alarmSpecies: 'D0008,D0009', system: this.form.system }).then(
        (res) => {
          if (res.code === 1000) {
            res.data.forEach((item, index) => {
              if (item.alarmSpecies === 'D0008') {
                self.alarmOptions[0].children.push({
                  label: self.dictObj.D0008Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0008Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              } else if (item.alarmSpecies === 'D0009') {
                self.alarmOptions[1].children.push({
                  label: self.dictObj.D0009Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0009Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              } else {
                // self.alarmOptions[2].children.push({
                //   label: self.dictObj.D0010Obj[item.alarmType],
                //   value: item.alarmType
                // })
                // self.alarmAllOptions.push({
                //   dictValue: self.dictObj.D0010Obj[item.alarmType],
                //   dictCode: item.alarmType
                // })
              }
            })
          }
        }
      )
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val]
    },
    //导出
    exportData () {      
      if(this.form.companyIds.length<1){
        this.$message.error("所属公司必选！");
        return
      }
      let exportData = {
        ...this.form,
        beginTime: formatDate(this.form.beginTime).split(" ")[0] + " 00:00:00",
        endTime: formatDate(this.form.endTime).split(" ")[0] + " 23:59:59",
      }
      let data = {
        baseUrl: 'base',
        fileName: `报警日监-${getCurent()}`,
        queryParam: JSON.stringify(exportData),
        queryPath: '/vehicleMonitor/alarmStatisticAnalysisExport'
      }
      console.log("看数据格式",exportData);
      this.downLoadStatus = true;
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
  }
}
</script>
<style lang="scss" scoped>
.dynamic-monitoring-alarm{
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  @include themify() {
    .table-search{
      background-color: #fff;
      padding: 16px;
    }
  }
}
</style>